<template>
  <dl v-hotkey="keymap">
    <dt>Hands</dt>
    <dd>{{ stats.hands }}</dd>
    <dt>Won</dt>
    <dd>{{ stats.won }}</dd>
    <dt>Lost</dt>
    <dd>{{ stats.lost }}</dd>
    <dt>Push</dt>
    <dd class="space">{{ stats.push }}</dd>
    <dt>Won</dt>
    <dd class="prom">{{ stats.percentWon }}%</dd>
    <dt>Bank</dt>
    <dd class="prom">${{ stats.bank }}</dd>
    <dt>Best</dt>
    <dd class="space prom">${{ stats.bestBank || stats.bank }}</dd>
    <dt><a @click.prevent="play">Play</a><small>(a)</small></dt>
    <dd><router-link v-if="stats.hands >= 50" to="/about#feedback">Feedback</router-link></dd>
  </dl>
</template>

<script>
export default {
  computed: {
    stats () {
      return this.$store.state.game.player.stats;
    },
    keymap () {
      return { a: this.play };
    },
  },
  methods: {
    play () {
      this.$store.dispatch('updateGame', { action: 'play' });
    },
  },
};
</script>

<style>
  a { cursor: pointer }
  small { color: #bbb; font-size: 12px }

  dl { color: #6f6f6f; width: 184px }
  dt { float: left }
  dt small { margin-left: 3px }
  dd { text-align: right }

  .space { margin-bottom: 24px }
  .prom { color: #222 }

  @media (prefers-color-scheme: dark) {
    small { color: #666 }
    .prom { color: #fff }
  }

  @media only screen and (max-width: 768px) {
    small { display: none }
    dt a { margin-left: -10px; padding: 10px }
    dt a:last-child { margin-right: -10px }
  }
</style>
