import Vue from 'vue';
import VueRouter from 'vue-router';
import About from '@/components/About.vue';
import Game from '@/components/Game.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Game,
  },
  {
    path: '/about',
    component: About,
    meta: {
      title: 'Blackjack Break / About',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: to => {
    if (to.hash) return { selector: to.hash };
    return true;
  },
});

export default router;
