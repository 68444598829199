import axios from 'axios';

const setup = baseURL => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.withCredentials = true;
  axios.defaults.timeout = 10000;
};

const post = (resource, data) => axios.post(resource, data);

export default { setup, post };
