<template>
  <div class="error">
    <p>{{ error }}</p>
    <a href="#" class="close" v-on:click="clearError"></a>
  </div>
</template>

<script>
export default {
  computed: {
    error () {
      return this.$store.state.error;
    },
  },
  methods: {
    clearError () {
      this.$store.dispatch('clearError');
    },
  },
};
</script>

<style scoped>
  .error {
    background: #f44;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    }

  .close {
    display: block;
    height: 16px;
    margin-left: 16px;
    text-align: center;
    width: 16px;
    }

  .close:before,
  .close:after {
    background: #fff;
    content: ' ';
    height: 17px;
    position: absolute;
    width: 1px;
    }

  .close:before {
    transform: rotate(45deg);
    }

  .close:after {
    transform: rotate(-45deg);
    }
</style>
