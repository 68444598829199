<template>
  <div class="about">
    <h1>About</h1>
    <div class="section">
      <h2>A minimal quick-play blackjack game</h2>
      <p>Towards the end of 2017, I found myself wanting to play a quick game of <em>something</em> while I was working. That something turned out to be blackjack. I tried a few web-based games and downloaded a bunch to my phone. All of them either felt kinda scammy, were hard to look at, or just played way too slow. I like making things, so I made Blackjack Break. I hope it's a fun diversion for you like it is for me.</p>
      <p class="mmb">&mdash; Carlos @ Leadtiger</p>
      <p><img :src="screenshotUrl" @click.prevent="showModal=true" width="300" height="191.63" alt="Blackjack Break screenshot" class="thumb" /></p>
    </div>
    <div class="section">
      <h2>Table</h2>
      <ul>
        <li>Blackjack pays 3 to 2</li>
        <li>Insurance pays 2 to 1</li>
        <li>Dealer <span @click="toggleStandOnSoft17" class="setting">{{ standOnSoft17 | friendlyStance }}</span> on soft 17</li>
        <li><span @click="toggleNumDecks" class="setting">{{ decks | friendlyDecks }}</span> 52-card deck<span v-if="decks > 1">s</span> of cards</li>
        <li>
          Dealer shuffles after dealing 75% of the cards<br />
          <a @click.prevent="play" v-if="showRestartButton" class="saveButton">Save changes and restart &rarr;</a>
        </li>
      </ul>
    </div>
    <div class="section" id="feedback">
      <h2>Feedback</h2>
      <p ref="encodedEmail">I'd love to hear from you. Email me at <a data-name="carlos" data-domain="leadtiger" data-tld="com" href="#" class="cmail" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false"></a>.</p>
    </div>
    <div class="section footer">
      <p>&copy; 2018-2021 <a href="https://leadtiger.com" target="_blank">Leadtiger</a></p>
    </div>
    <modal v-if="showModal" @close="showModal=false">
      <div slot="content">
        <img :src="screenshotUrl" alt="Blackjack Break screenshot" />
      </div>
    </modal>
  </div>
</template>

<script>
/* global fathom */
import modal from './modal.vue';

const numMap = { 1: 'One', 2: 'Two' };
const boolMap = { true: 'stands', false: 'hits' };

export default {
  components: {
    modal,
  },
  data () {
    return {
      showModal: false,
      showRestartButton: false,
      decks: 1,
      standOnSoft17: true,
    };
  },
  mounted () {
    fathom('trackPageview', { path: '/about' });

    if (this.$store.state.game.rules) {
      this.decks = this.$store.state.game.rules.decks;
      this.standOnSoft17 = this.$store.state.game.rules.standOnSoft17;
    }
  },
  computed: {
    screenshotUrl () {
      return matchMedia('(prefers-color-scheme: dark)').matches
        ? '/blackjack-break-screenshot-dark.png' : '/blackjack-break-screenshot-light.png';
    },
  },
  filters: {
    friendlyDecks (decks) {
      return numMap[decks];
    },
    friendlyStance (standOnSoft17) {
      return boolMap[standOnSoft17];
    },
  },
  methods: {
    toggleNumDecks () {
      this.decks = this.decks === 1 ? 2 : 1;
      this.showRestartButton = true;
    },
    toggleStandOnSoft17 () {
      this.standOnSoft17 = !this.standOnSoft17;
      this.showRestartButton = true;
    },
    play () {
      this.$store.dispatch('updateGame', { action: 'play', decks: this.decks, standOnSoft17: this.standOnSoft17 })
        .then(() => this.$router.push('/'));
    },
  },
};
</script>

<style scoped>
  .about {
    font-size: 18.5px;
    font-weight: 300;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 610px;
    padding: 5px 20px 16px;
    }

  div.section { margin-bottom: 64px }
  div.section:last-child { margin: 0 }
  h1 { font-size: 40px; font-weight: 400; margin-bottom: 26px }
  h2 { font-size: 30px; margin-bottom: 14px }
  p, ul { margin-bottom: 16px }
  ul li { margin-left: 20px }
  em { font-style: italic }
  img.thumb { box-shadow: 0px 1rem 2rem -0.375rem #ccc; cursor: pointer }
  .setting { border-bottom: 1px solid #ccc; cursor: pointer; user-select: none }
  .saveButton { color: #b0b }
  .cmail:after { content: attr(data-name) "@" attr(data-domain) "." attr(data-tld) }
  .mmb { margin-bottom: 26px }

  .footer {
    color: #666;
    font-size: 13px;
    letter-spacing: 0.5px;
    padding-bottom: 16px;
    }

  @media (prefers-color-scheme: dark) {
    .about { color: rgba(255, 255, 255, 0.9) }
    .setting { border-bottom: 1px solid #666 }
    .footer { color: #bbb }
    img.thumb { border: 1px solid #fff; box-shadow: none }
  }

  @media only screen and (max-width: 768px) {
    .about { font-size: 16px }
    img.thumb { display: none }
    div.section { margin-bottom: 48px }
    h1 { font-size: 30px }
    h2 { font-size: 24px; line-height: 1.3 }
  }
</style>
