import Vue from 'vue';
import VueHotkey from 'v-hotkey';
import ApiService from './services/api';
import store from './store';
import App from './App.vue';
import router from './router';

Vue.use(VueHotkey);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Blackjack Break';
  next();
});

ApiService.setup(process.env.VUE_APP_API_ADDR);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
