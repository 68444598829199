import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from '../services/api';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    error: '',
    game: {},
  },

  actions: {

    async updateGame ({ commit }, {
      action, bet, decks, standOnSoft17,
    }) {
      const timeouts = [];

      try {
        const { data: game } = await ApiService.post(`/actions/${action}`, { bet, decks, standOnSoft17 });

        commit('clearError');
        timeouts.forEach(timeout => clearTimeout(timeout));

        const { cards } = game.dealer;
        let newCards = [];

        if (Object.keys(cards).length > 0) newCards = cards.splice(2);

        const { result } = game;

        game.result = { dealer: '', right: '', left: '' };

        commit('setGame', game);

        if (game.player.right.handValue >= 21) {
          commit('setResult', result);
          return;
        }

        if (newCards.length < 1) {
          timeouts.push(setTimeout(() => commit('setResult', result), 300));
          return;
        }

        newCards.forEach((card, index) => {
          timeouts.push(
            setTimeout(() => {
              commit('addCard', card);

              if (index === newCards.length - 1) {
                timeouts.push(setTimeout(() => commit('setResult', result), 400));
              }
            }, index * 200),
          );
        });
      } catch (error) {
        commit('setError', 'Sorry, there was a problem accessing the server. Try reloading this page.');
      }
    },

    incBet ({ commit }, amount) {
      commit('incBet', amount);
    },

    clearBet ({ commit }) {
      commit('clearBet');
    },

    decBank ({ commit }, amount) {
      commit('decBank', amount);
    },

    incBank ({ commit }, amount) {
      commit('incBank', amount);
    },

    clearBank ({ commit }) {
      commit('clearBank');
    },

    clearMsg ({ commit }) {
      commit('clearMsg');
    },

    setMsg({ commit }, { type, text }) {
      commit('setMsg', { type, text });
    },

    setError({ commit }, error) {
      commit('setError', error);
    },

    clearError({ commit }) {
      commit('clearError');
    },

  },

  mutations: {

    setGame (state, game) {
      state.game = game;
    },

    addCard (state, card) {
      state.game.dealer.cards.push(card);
    },

    setResult (state, result) {
      state.game.result = result;
    },

    incBet (state, amount) {
      state.game.player.right.bet += amount;
    },

    clearBet (state) {
      state.game.player.right.bet = 0;
    },

    decBank (state, amount) {
      state.game.player.bank -= amount;
    },

    incBank (state, amount) {
      state.game.player.bank += amount;
    },

    clearBank (state) {
      state.game.player.bank = 0;
    },

    clearMsg (state) {
      state.game.msg = {};
    },

    setMsg (state, { type, text }) {
      state.game.msg = { type, text };
    },

    setError (state, error) {
      state.error = error;
    },

    clearError (state) {
      state.error = '';
    },

  },

});
