<template>
  <ul class="actions" v-hotkey="keymap">
    <li v-for="(status, action, index) in actions" :key="index">
      <div v-if="canTakeAction(action)">
        <a @click.prevent="takeAction(action)">{{ action | capitalize }}</a>
        <small>({{ action | shortcutKey }})</small>
      </div>
      <div v-else>
        {{ action | capitalize }}
      </div>
    </li>
    <li>
      <div v-if="canTakeAction('insurance') && canBuyInsurance()">
        <a @click.prevent="takeAction('insurance')">Insurance</a>
        <small>(q)</small>
        <span class="noInsurance">
          or <a @click.prevent="takeAction('insurance', 1)" class="insure">no</a><small>(w)</small>
        </span>
      </div>
      <div v-else>
        Insurance
        <span v-if="canTakeAction('insurance')">
          or <a @click.prevent="takeAction('insurance', 1)" class="insure">no</a><small>(w)</small>
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
const shortcuts = {
  play: 'a',
  deal: 'a',
  hit: 'a',
  stand: 's',
  double: 'd',
  split: 'f',
  surrender: 'g',
  insurance: 'q',
  noInsurance: 'w',
};

export default {
  computed: {
    player () {
      return this.$store.state.game.player;
    },
    actions () {
      const {
        deal, hit, stand, double, split, surrender,
      } = this.player.actions;

      return {
        deal, hit, stand, double, split, surrender,
      };
    },
    keymap () {
      return {
        a: this.handleShortcut,
        s: this.handleShortcut,
        d: this.handleShortcut,
        f: this.handleShortcut,
        g: this.handleShortcut,
        q: this.handleShortcut,
        w: this.handleShortcut,
      };
    },
  },
  filters: {
    capitalize: word => {
      if (!word) return '';
      const string = word.toString();
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    shortcutKey: action => shortcuts[action],
  },
  methods: {
    canTakeAction (action) {
      return this.player.actions[action] && this.player.right.bet > 0;
    },
    canBuyInsurance () {
      return this.player.bank >= this.player.right.bet / 2;
    },
    handleShortcut (e) {
      if (this.canTakeAction('insurance')) {
        if (e.key === 'q' && this.canBuyInsurance()) this.takeAction('insurance');
        if (e.key === 'w') this.takeAction('insurance', 1);
      } else if (this.player.actions.play && e.key === 'a') {
        this.takeAction('play');
      } else {
        Object.keys(shortcuts).forEach(key => {
          if (shortcuts[key] === e.key && this.canTakeAction(key)) {
            this.takeAction(key);
          }
        });
      }
    },
    takeAction (action, declineInsurance) {
      let { bet } = this.player.right;
      if (action === 'insurance') bet = declineInsurance ? 0 : 1;
      this.$store.dispatch('updateGame', { action, bet });
    },
  },
};
</script>

<style scoped>
  small { color: #bbb; font-size: 12px }
  .actions { color: #bbb; height: 172px }
  .actions li { display: block; line-height: 1.3 }
  .actions li span { color: #333 }
  .actions li small { margin-left: 3px }

  @media (prefers-color-scheme: dark) {
    small, .actions, .actions li span { color: #666 }
  }

  @media only screen and (max-width: 768px) {
    small { display: none }
    .actions { display: flex; flex-flow: column wrap; height: 202px; width: 210px }
    .actions li { width: 105px; min-height: 50px; white-space: nowrap }
    .actions li a { margin-left: -10px; padding: 10px }
    .actions li a.insure { margin-left: auto; padding: 10px 1px }
    .actions li .noInsurance { margin-left: -8px }
  }
</style>
