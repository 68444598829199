<template>
  <div>
    <ol class="chips" v-hotkey="keymap">
      <li class="bet">${{ player.right.bet }}<span v-if="player.left.bet">x2</span></li>
      <li>
        <div v-if="actions.deal && player.right.bet > 0">
          <a @click.prevent="clearBet">x</a><br /><small>x</small>
        </div>
        <div v-else>
          x
        </div>
      </li>
      <li v-for="(chip, index) in player.chips" :key="index">
        <div v-if="actions.deal && player.bank >= chip">
          <a @click.prevent="incBet(chip)">{{ chip | friendlyChip }}</a><br /><small>{{ index+1 }}</small>
        </div>
        <div v-else>
          {{ chip | friendlyChip }}
        </div>
      </li>
      <li>
        <div v-if="actions.deal && player.bank > 0">
          <a @click.prevent="allIn">All In</a><br /><small>z</small>
        </div>
        <div v-else>
          All In
        </div>
      </li>
    </ol>
    <p class="bank">${{ player.bank }}</p>
  </div>
</template>

<script>
const shortcuts = {
  25: '1',
  100: '2',
  1000: '3',
  10000: '4',
  100000: '5',
};

export default {
  computed: {
    player () {
      return this.$store.state.game.player;
    },
    actions () {
      const {
        deal, hit, stand, double, split, surrender,
      } = this.player.actions;

      return {
        deal, hit, stand, double, split, surrender,
      };
    },
    keymap () {
      return {
        x: this.handleShortcut,
        z: this.handleShortcut,
        1: this.handleShortcut,
        2: this.handleShortcut,
        3: this.handleShortcut,
        4: this.handleShortcut,
        5: this.handleShortcut,
      };
    },
  },
  filters: {
    friendlyChip: chip => (chip < 1000 ? chip : `${(chip / 1000)}k`),
  },
  methods: {
    handleShortcut (e) {
      if (!this.player.actions.deal) return false;

      if (e.key === 'x') {
        this.clearBet();
      } else if (e.key === 'z') {
        this.allIn();
      } else {
        Object.keys(shortcuts).forEach(key => {
          if (shortcuts[key] === e.key && this.player.bank >= key) this.incBet(Number(key));
        });
      }

      return true;
    },
    clearBet () {
      if (this.player.right.bet > 0) {
        const { bet } = this.player.right;
        this.$store.dispatch('clearBet');
        this.$store.dispatch('incBank', bet);
      }
    },
    incBet (chip) {
      if (this.player.bank - chip >= 0) {
        this.$store.dispatch('incBet', chip);
        this.$store.dispatch('decBank', chip);
        this.$store.dispatch('clearMsg');
      }
    },
    allIn () {
      if (this.player.bank > 0) {
        this.$store.dispatch('incBet', this.player.bank);
        this.$store.dispatch('clearBank');
        this.$store.dispatch('clearMsg');
      }
    },
  },
};
</script>

<style scoped>
  ol { list-style: none }
  li { display: inline-block }
  small { color: #bbb; font-size: 12px }

  .chips { color: #bbb; min-height: 50px }
  .chips li { margin-right: 8px; vertical-align: top }
  .chips li:last-child { margin-right: 0 }
  .chips li div { text-align: center }
  .chips .bet { color: #333; min-width: 72px }

  @media (prefers-color-scheme: dark) {
    .chips { color: #666 }
    .chips .bet { color: #fff }
  }

  @media only screen and (max-width: 768px) {
    small { display: none }
    .chips li { margin-right: 16px }
    .chips li.bet { margin-right: 8.8px }
  }
</style>
