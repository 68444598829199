<template>
  <div class="hands">
    <transition-group tag="ol" class="dealer hand" :name="transitionName" appear @before-leave="beforeLeave">
      <li v-for="(card, index) in dealer.cards" v-html="$options.filters.playingCard(card)" :class="['card', card.suite]" :key="card.text + index"></li>
      <li v-if="isHoleCard" key="k1">?<br />?</li>
      <li v-if="dealer.handValue" :class="['handValue', game.result.dealer]" key="k2" v-html="dealer.handValue"></li>
    </transition-group>
    <transition-group tag="ol" class="player hand" name="slide" appear @before-leave="beforeLeave">
      <li v-for="(card, index) in player.right.cards" v-html="$options.filters.playingCard(card)" :class="['card', card.suite]" :key="card.text + index"></li>
      <li v-if="player.right.handValue" :class="['handValue', game.result.right]" key="k3" v-html="player.right.handValue"></li>
      <li v-if="player.right.active && player.left.cards" key="k5" class="active">&#10094;</li>
    </transition-group>
    <transition-group tag="ol" class="player hand" name="slide" appear @before-leave="beforeLeave">
      <li v-for="(card, index) in player.left.cards" v-html="$options.filters.playingCard(card)" :class="['card', card.suite]" :key="card.text + index"></li>
      <li v-if="player.left.handValue" :class="['handValue', game.result.left]" key="k4" v-html="player.left.handValue"></li>
      <li v-if="player.left.active && player.left.cards" key="k6" class="active">&#10094;</li>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const suite = {
  clubs: '♣&#xFE0E;',
  diamonds: '♦&#xFE0E;',
  hearts: '♥&#xFE0E;',
  spades: '♠&#xFE0E;',
  '?': '?',
};

export default {
  computed: {
    isHoleCard () {
      return this.dealer.cards && this.dealer.cards.length === 1;
    },
    ...mapState({
      game: state => state.game,
      dealer: state => state.game.dealer,
      player: state => state.game.player,
    }),
    transitionName () {
      return this.dealer.cards.length === 2 ? 'flip' : 'slide';
    },
  },
  filters: {
    playingCard: card => `${card.text}<br />${suite[card.suite]}`,
  },
  methods: {
    beforeLeave (el) {
      el.style.position = 'absolute';
      el.style.zIndex = -1;
    },
  },
};
</script>

<style scoped>
  ol { list-style: none }
  li { display: inline-block }

  .hands { min-height: 242px }
  .hands ol { min-height: 76px; margin-left: -8px }

  .hand li {
    display: inline-block;
    font-size: 25px;
    font-weight: 900;
    line-height: 1.2;
    text-align: center;
    transition: transform 0.5s;
    width: 40px;
    }

  .hand li.handValue {
    background: #f4f4f4;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    margin: 4px 0 0 10px;
    padding: 3px 4px 2px;
    text-align: center;
    vertical-align: top;
    width: auto;
    }

  .hand li.active {
    font-size: 16px;
    margin: 4px 0 0 8px;
    vertical-align: top;
    }

  .hearts, .diamonds { color: #f44 }

  .hand li.blackjack { background: #b0b; color: #fff }
  .hand li.win { background: #42b983; color: #fff }
  .hand li.lose { background: #f44; color: #fff }
  .hand li.push { background: #2d92ff; color: #fff }

  .slide-enter { opacity: 0; transform: translateY(-100%) }
  .slide-leave-to { opacity: 0; transform: translate(-100%, -50%) }
  .slide-enter-active { transition: all 0.5s ease }
  .slide-leave-active { transition: all 0.5s ease }

  .flip-enter-active, .flip-leave-active { transition: all 0.5s }
  .flip-enter, .flip-leave-to { transform: rotateY(90deg) }

  @media (prefers-color-scheme: dark) {
    .hearts, .diamonds { opacity: .9 }
    .hand li.handValue { background: #444 }
    .hand li.blackjack { background: rgba(187, 0, 187, .75) }
    .hand li.win { background: rgba(66, 185, 131, .75) }
    .hand li.lose { background: rgba(255, 68, 68, .75) }
    .hand li.push { background: rgba(45, 146, 255, .75) }
  }
</style>
