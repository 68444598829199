<template>
  <div class="game" v-if="dealerIsHere">
    <Message />
    <Hands />
    <div v-if="playerHasChips">
      <Actions />
      <Chips />
    </div>
    <div v-else>
      <Stats />
    </div>
  </div>
</template>

<script>
import Message from '@/components/Message.vue';
import Hands from '@/components/Hands.vue';
import Actions from '@/components/Actions.vue';
import Chips from '@/components/Chips.vue';
import Stats from '@/components/Stats.vue';

export default {
  name: 'Game',
  components: {
    Message,
    Hands,
    Actions,
    Chips,
    Stats,
  },
  computed: {
    dealerIsHere () {
      return this.$store.state.game.dealer;
    },
    playerHasChips () {
      const { player } = this.$store.state.game;
      return player.bank + player.right.bet > 0;
    },
    isNewGame () {
      const { player } = this.$store.state.game;
      return player.stats.hands < 1 && player.right.bet === 0;
    },
  },
  mounted () {
    document.documentElement.className = 'playing';
    document.body.className = 'playing';

    this.$store.dispatch('updateGame', { action: 'init' })
      .then(() => {
        if (this.isNewGame) {
          this.$store.dispatch('setMsg', { text: 'Click your chips to place a bet.' });
        }
      });
  },
  beforeDestroy () {
    document.documentElement.className = '';
    document.body.className = '';
  },
};
</script>

<style scoped>
  .game {
    font-size: 16px;
    left: 50%;
    height: 536px;
    min-width: 330px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    }

  @media only screen and (max-width: 768px) {
    .game { height: 560px }
  }
</style>
