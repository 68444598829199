<template>
  <div id="app">
    <Error v-if="error" />
    <div class="wrap">
      <div class="header">
        <router-link to="/" exact>BB</router-link>
        <router-link to="/about">??</router-link>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Error from '@/components/Error.vue';

export default {
  components: {
    Error,
  },
  computed: {
    error () {
      return this.$store.state.error;
    },
  },
};
</script>

<style>
  @import './assets/reset.css';

  html, body { height: 100% }

  body {
    background: #fff;
    color: #333;
    font-family: Roboto, sans-serif;
    text-decoration: none;
    -webkit-font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    }

  html.playing {
    overflow: hidden;
    }

  body.playing {
    overflow: hidden;
    position: relative;
    }

  a {
    color: #000;
    text-decoration: underline;
    }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    }

  .header a {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 10px;
    }

  .header a.router-link-active { text-decoration: none }

  @media (prefers-color-scheme: dark) {
    body { background: #000; color: #fff }
    a { color: #fff }
  }

  @media only screen and (max-width: 768px) {
    a[href="/"].router-link-exact-active { visibility: hidden }
  }
</style>
