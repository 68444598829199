<template>
  <div class="status">
    <transition name="fade">
      <p v-if="message" :class="message.type" v-html="message.text"></p>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    message () {
      return this.$store.state.game.msg;
    },
  },
};
</script>

<style scoped>
  .status { min-height: 46px }
  .notice { color: #bbb }
  .alert { color: #f44 }

  .fade-enter-active, .fade-leave-active { transition: opacity 0.2s }
  .fade-enter, .fade-leave-to { opacity: 0 }

  @media (prefers-color-scheme: dark) {
    .notice { color: #666 }
    .alert { opacity: .9 }
  }
</style>
